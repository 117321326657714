import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {OAuth2TokensDto} from '../../../../services/authentication/model/oAuth2TokensDto';
import {LoginRequestDto} from '../../../../services/authentication/model/loginRequestDto';
import {ReloginRequestDto} from '../../../../services/authentication/model/reloginRequestDto';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    private serviceUrl = `${environment.baseUrl}/authentication`;

    constructor(
        private httpClient: HttpClient
    ) {
    }

    loginRequest(query: LoginRequestDto): Observable<OAuth2TokensDto> {
        const url = `${this.serviceUrl}/login`;
        return this.httpClient.post<OAuth2TokensDto>(url, query, {headers: {Public: 'true'}});
    }

    reLoginRequest(credentials: ReloginRequestDto): Observable<OAuth2TokensDto> {
        const url = `${this.serviceUrl}/relogin`;
        return this.httpClient.post<OAuth2TokensDto>(url, credentials, {headers: {Public: 'true'}});
    }
}
