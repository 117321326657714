<form class="df-c">
    <div class="confirm-text">
        <div *ngIf="data.text" [innerHTML]="data.text" class="dialogDelete__text"></div>
    </div>
    <div class="confirm-actions">
        <button (click)="closeDialog(true)" [ngClass]="{'focused': selected == 1}"
                class="btn alternate-green">{{ data.submitText ? (data.submitText | translate) : ('common.buttons.yes' | translate) }}
        </button>
        <button (click)="closeDialog()" *ngIf="data.alternateButtonText" [ngClass]="{'focused': selected == 3}"
                class="btn alternate-green"
                type="button">{{ data.alternateButtonText | translate }}
        </button>
        <button (click)="closeDialog(false)" *ngIf="!data.hideCancel" [ngClass]="{'focused': selected == 2}"
                class="btn light-text"
                type="button">{{ data.cancelText ? (data.cancelText | translate) : ('common.buttons.no' | translate) }}
        </button>
    </div>
</form>
