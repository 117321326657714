import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CustomerResponse} from '../../../../services/dictionaries/model/customerResponse';
import {Observable} from 'rxjs';
import {CustomerRequest} from '../../../../services/dictionaries/model/customerRequest';
import {FileDto} from '../../../../services/dictionaries/model/fileDto';
import {BrokerLoadCarrierCompanyModel} from '../../../../services/broker/model/brokerLoadCarrierCompanyModel';
import {PageableResponse} from '@common/_models/core.models';

@Injectable({
    providedIn: 'root'
})

export class DictionariesService {
    private serviceUrl = `${environment.baseUrl}/dictionaries`;
    private brokerUrl = `${environment.baseUrl}/broker`;

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getAuthUserProfile(): Observable<CustomerResponse> {
        const url = `${this.serviceUrl}/broker/profile`;
        return this.httpClient.get<CustomerResponse>(url);
    }

    editProfile(query: any): Observable<CustomerRequest> {
        const url = `${environment.baseUrl}/dictionaries/broker/profile`;
        return this.httpClient.post<CustomerResponse>(url, query);
    }

    uploadFile(file: File): Observable<FileDto> {
        const url = `${this.serviceUrl}/storage/upload`;
        return this.httpClient.post<FileDto>(url, file);
    }

    searchCarrier(value: number): Observable<BrokerLoadCarrierCompanyModel> {
        const url = `${this.brokerUrl}/carrier/company/get/${value}`;
        return this.httpClient.get<BrokerLoadCarrierCompanyModel>(url);
    }

    searchUnit(value: string, urlEnd: string): Observable<PageableResponse<string>> {
        const url = `${this.brokerUrl}/carrier/${urlEnd}`;
        return this.httpClient.post<PageableResponse<string>>(url, {value});
    }
}
