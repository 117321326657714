import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {

    list(): (string | null)[] {
        const result: (string | null)[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i)) {
                result.push(localStorage.key(i));
            }
        }
        return result;
    }

    get(key: string): string | null {
        return localStorage.getItem(key);
    }

    set(key: string, value: string): void {
        localStorage.removeItem(key);
        localStorage.setItem(key, value);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }
}
