import {AccessTokenPayload} from '@common/_models/core.models';
import {createAction, props} from '@ngrx/store';
import {NameResponse} from '../../../../services/chat/model/nameResponse';
import {CustomerResponse} from '../../../../services/dictionaries/model/customerResponse';
import {CustomerRequest} from '../../../../services/dictionaries/model/customerRequest';
import {HttpErrorResponse} from '@angular/common/http';

export const HttpErrorDetected = createAction(
    '[Core] Http Error Detected',
    props<{ key: string, data?: NameResponse }>()
);

export const SetPageTitle = createAction(
    '[Core] Set Page Title',
    props<{ payload: string }>()
);

export const SetTabId = createAction(
    '[Core] Set Tab Id',
    props<{ tabId: string }>()
);

export const SetTokenSuccess = createAction(
    '[User] Set Token Success',
    props<{ payload: { accessToken: string, accessTokenPayload: AccessTokenPayload } }>()
);

export const LoadUserProfileSuccess = createAction(
    '[User] Load User Profile Success',
    props<{ payload: CustomerResponse }>()
);

export const LogOutSuccess = createAction(
    '[User] Log Out Success',
);


export const EditProfile = createAction(
    '[Login] Edit Profile',
    props<{payload: CustomerRequest}>()
);

export const EditProfileSuccess = createAction(
    '[Login] Edit Profile Success',
    props<{payload: CustomerResponse}>()
);

export const EditProfileFail = createAction(
    '[Login] Edit Profile Fail',
    props<{payload?: HttpErrorResponse}>()
);

