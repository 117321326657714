import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Client} from '@stomp/stompjs';

export interface WsMessageModel {
    type: string,
    content: any,
    senderTabId?: string,
}

export const stompServiceData$ = new BehaviorSubject<WsMessageModel | null>(null);

@Injectable({
    providedIn: 'root'
})

export class StompNotificationsService {
    private wsClient: Client;

    initWsConnection(token?: string) {
        this.disconnectWs();
        if (token) {
            setTimeout(() => {
                if (!this.wsClient) {
                    this.wsClient = new Client();
                }
                this.wsClient.brokerURL = `wss://${environment.ws}/ws/websocket?access_token=${token}`;
                this.wsClient.activate();
                this.onWsConnected();
                this.wsHealthCheck();
            }, 200);
        }
    }

    wsHealthCheck() {
        this.wsClient.onWebSocketError = () => {
            setTimeout(() => {
                this.initWsConnection();
            }, 3000);
        };
    }

    onWsConnected() {
        this.wsClient.onConnect = () => {
            this.wsClient.subscribe("/user/queue/ws", (message) => {
                if (message.command == 'MESSAGE') {
                    const incomingMessage: WsMessageModel = message.body ? JSON.parse(message.body) : null;

                    if (incomingMessage && incomingMessage.type) {
                        stompServiceData$.next(incomingMessage);
                    }
                }
            }, {
                'auto-delete': 'true',
                'ack': 'auto'
            });
        };
    }

    disconnectWs() {
        this.wsClient && this.wsClient.deactivate().then();
    }
}
