import * as CoreActions from '@common/_store/core.actions';
import * as coreSelectors from '@common/_store/core.selectors';

import {Injectable} from '@angular/core';
import {OAuth2TokensDto} from '../../../../services/authentication/model/oAuth2TokensDto';
import {LoginRequestDto} from '../../../../services/authentication/model/loginRequestDto';
import {firstValueFrom, map, Observable, of, Subject, take, tap} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {AccessTokenPayload, ConfirmationDialogModel} from '../_models/core.models';
import {jwtDecode} from 'jwt-decode';
import {Store} from '@ngrx/store';
import {AuthenticationService} from './authentication.service';
import {UserDto} from '../../../../services/dictionaries/model/userDto';
import {NameResponse} from '../../../../services/chat/model/nameResponse';
import {HttpErrorResponse, HttpEvent} from '@angular/common/http';
import {ReloginRequestDto} from '../../../../services/authentication/model/reloginRequestDto';
import {ActivatedRoute, Router} from '@angular/router';
import {DictionariesService} from '@common/_services/dictionaries.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '@common/_components/confirmation-dialog/confirmation-dialog.component';
import {CustomerResponse} from '../../../../services/dictionaries/model/customerResponse';
import {ToastrService} from 'ngx-toastr';
import {StompNotificationsService} from '@common/_services/stomp-notifications.service';
import {v4} from 'uuid';
import {ConfigService} from '@common/_services/config.service';

@Injectable({
    providedIn: 'root'
})

export class CoreService {
    public httpError$ = new Subject<HttpErrorResponse>();

    constructor(
        private localStorageService: LocalStorageService,
        private authenticationService: AuthenticationService,
        private dictionariesService: DictionariesService,
        private toastr: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store$: Store,
        private dialog: MatDialog,
        private stompService: StompNotificationsService,
        private configService: ConfigService,
    ) {

    }

    initializeApp() {
        if (window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/tracking')) {
            return;
        }

        if (!this.localStorageService.get('accessToken')) {
            this.router.navigate(['login']).then();
            return;
        }

        this.setUserProfile();
        this.stompService.initWsConnection(this.localStorageService.get('accessToken') as string);
    }

    setTabId() {
        const tabId: string = v4();
        this.store$.dispatch(CoreActions.SetTabId({tabId}));
    }

    selectTabId$(): Observable<string | undefined> {
        return this.store$.select(coreSelectors.getTabId);
    }

    login(request: LoginRequestDto): Observable<OAuth2TokensDto> {
        return this.authenticationService.loginRequest(request).pipe(
            tap((resp: OAuth2TokensDto) => {
                this.setAuthDataToLs(resp);
                this.setUserProfile();
            })
        );
    }

    reLogin(request: ReloginRequestDto): Observable<OAuth2TokensDto> {
        return this.authenticationService.reLoginRequest(request).pipe(
            map(res => {
                this.setAuthDataToLs(res);
                this.setUserProfile();
                return res;
            })
        );
    }

    setAuthDataToLs(authData: OAuth2TokensDto) {
        this.localStorageService.set('accessToken', authData.accessToken!);
        this.localStorageService.set('refreshToken', authData.refreshToken!);
        this.localStorageService.set('expiresIn', String(authData.expiresIn!));

        const accessTokenPayload: AccessTokenPayload = jwtDecode<AccessTokenPayload>(authData.accessToken!);
        localStorage.setItem('deviceId', accessTokenPayload.session.deviceId!);
    }

    setUserProfile() {
        this.dictionariesService.getAuthUserProfile().pipe(
            take(1),
            tap((profile: UserDto) => {
                this.store$.dispatch(CoreActions.LoadUserProfileSuccess({payload: profile}));
                this.setTokenPayloadToStore();
            })
        ).subscribe();
    }

    setTokenPayloadToStore() {
        const accessToken = this.localStorageService.get('accessToken');
        if (accessToken) {
            const accessTokenPayload: AccessTokenPayload = jwtDecode<AccessTokenPayload>(accessToken);
            this.store$.dispatch(CoreActions.SetTokenSuccess({payload: {accessToken, accessTokenPayload}}));
        }
    }

    navigateToLogin(sessionExpired?: boolean): Observable<HttpEvent<any>> {
        this.localStorageService.clear();
        this.dialog.closeAll();
        this.store$.dispatch(CoreActions.LogOutSuccess());
        this.router.navigate(['login'], {relativeTo: this.activatedRoute}).then(() => {
            if (sessionExpired) {
                this.toastr.error('Please, login again', 'Session expired');
            }
        });

        return of();
    }

    selectCurrentUser$(): Observable<CustomerResponse | undefined> {
        return this.store$.select(coreSelectors.selectUserProfile);
    }

    setPageTitle(title: string) {
        return this.store$.dispatch(CoreActions.SetPageTitle({payload: title}));
    }

    selectPageTitle$(): Observable<string | undefined> {
        return this.store$.select(coreSelectors.selectPageTitle);
    }

    proceedHTTPError(rawError: HttpErrorResponse, key: string, data?: NameResponse) {
        this.httpError$.next(rawError);
        this.store$.dispatch(CoreActions.HttpErrorDetected({key, data}));
    }

    async showConfirmation(data: ConfirmationDialogModel, config?: MatDialogConfig): Promise<boolean | undefined> {
        const confirmationDialog$ = this.dialog.open(ConfirmationDialogComponent, {
            width: '100%',
            maxWidth: "380px",
            panelClass: ['confirm-dialog'],
            ...config && {...config},
            data
        });

        return firstValueFrom(confirmationDialog$.afterClosed()).then(data => {
            return data?.result;
        });
    }

    openDialog(component: any, config: MatDialogConfig, dialogData?: any) {
        return this.dialog.open(
            component,
            {
                ...this.configService.defaultDialogConfig, ...config,
                data: dialogData,
            },
        );
    }
}
