import * as CoreActions from '@common/_store/core.actions';

import {createReducer, on} from '@ngrx/store';
import {AccessTokenPayload} from '@common/_models/core.models';
import {CustomerResponse} from '../../../../services/dictionaries/model/customerResponse';

export const coreStateFeatureKey = 'coreState';

export interface CoreState {
    tabId?: string,
    accessToken?: string,
    accessTokenPayload?: AccessTokenPayload,
    userProfile?: CustomerResponse,
    pageTitle?: string,
}

export const coreReducer = createReducer(
    {},

    on(CoreActions.SetTabId, (state, action) => ({
        ...state,
        tabId: action.tabId
    })),

    on(CoreActions.SetTokenSuccess, (state, action) => ({
        ...state,
        accessToken: action.payload.accessToken,
        accessTokenPayload: action.payload.accessTokenPayload
    })),

    on(CoreActions.LoadUserProfileSuccess, (state, action) => ({
        ...state,
        userProfile: action.payload
    })),

    on(CoreActions.SetPageTitle, (state, action) => ({
        ...state,
        pageTitle: action.payload
    })),

    on(CoreActions.LogOutSuccess, () => ({}))
);


