import {Component, HostListener, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogModel} from '@common/_models/core.models';
import {AngularComponentsModule} from '@common/angular-components.module';

@Component({
    selector: 'ovn-confirmation-dialog',
    standalone: true,
    imports: [AngularComponentsModule],
    templateUrl: './confirmation-dialog.component.html',
    styleUrl: 'confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
    public selected: number = 1;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel,
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>
    ) {
    }

    closeDialog(result?: boolean) {
        this.dialogRef.close({result});
    }

    @HostListener('document:keydown', ['$event'])
    keydown($event: KeyboardEvent) {
        if ($event.code == 'ArrowRight') {
            if (this.selected != 3) {
                this.selected = this.selected + 1;
            }
        }
        if ($event.code == 'ArrowLeft') {
            if (this.selected != 1) {
                this.selected = this.selected - 1;
            }
        }
        if ($event.code == 'Enter') {
            if (this.selected == 1) {
                this.closeDialog(true);
            }
            if (this.selected == 2) {
                this.closeDialog(false);
            }
            if (this.selected == 3) {
                this.closeDialog();
            }
        }
    }
}
