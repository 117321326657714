import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CoreState, coreStateFeatureKey} from '@common/_store/core.reducer';

export const getCoreState = createFeatureSelector<CoreState>(coreStateFeatureKey);

export const getTabId = createSelector(
    getCoreState,
    (state) => state.tabId
);

export const selectUserProfile = createSelector(
    getCoreState,
    (state) => state.userProfile
);

export const selectPageTitle = createSelector(
    getCoreState,
    (state) => state.pageTitle
);
